export const dateParser = (num) => {
    let options = {
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        weekday: 'long', 
        year: 'numeric',
        month: 'short', 
        day: 'numeric'
    };

    // Utilisation correcte de new Date(num)
    let date = new Date(num);

    // Utilisation de toLocaleDateString pour formater la date
    let formattedDate = date.toLocaleDateString('fr-FR', options);

    return formattedDate.toString();
}

export const Isempty = (value) =>{
    return (
        value === undefined || value === null || 
        (typeof value === 'Object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    )
}