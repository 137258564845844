import { GET_TRENDS } from "../actions/user.action";

const initialState = {
  trends: [], // état initial avec une liste vide
};

export default function trendsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRENDS:
      return {
        ...state, // garder les autres propriétés de l'état
        trends: action.payload, // mettre à jour les tendances avec la charge utile
      };
    default:
      return state; // renvoyer l'état précédent si l'action n'est pas reconnue
  }
}
