import "./styles/index.scss";
import rootReducer from './reducers';
import ReactDOM from 'react-dom/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {  getToken } from "firebase/messaging";
import { getTrends, getUsers } from "./actions/user.action";
import { initializeApp } from 'firebase/app'; // Nouvelle syntaxe modulaire
import { getMessaging } from 'firebase/messaging'; // Importez la partie messaging séparément
import { useEffect } from "react";

const url = process.env.REACT_APP_API_URL  //|| 'http://localhost:3000/api' || 'https://sen-teranga.com/api'
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  authDomain: `${process.env.REACT_APP_API_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_API_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_API_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_API_MESSAGING_SENDER_ID}`,
  appId: `${process.env.APP_ID}`
};

const app  = initializeApp(firebaseConfig)

const messaging = getMessaging(app)


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered:', registration);
    })
    .catch((err) => console.log('Service Worker registration failed:', err));
}

const vapidKey = `${process.env.REACT_APP_API_VAPID_KEY}`


export async function TokenVerify(){

  getToken(messaging,{vapidKey:vapidKey})
  .then((token)=>{
    if(token){
      console.log('Token FCM exist')
    }
    else{
      requestPermission()
    }
  })
  .catch((err)=>{
    console.log(err)
  })

}
//export const Start = () => {
//  const dataUser = useSelector((state)=> state.userReducer)
//  const uid = dataUser.id
//  useEffect(()=>{
//    TokenVerify(uid ? uid : 'Null')
//  },[uid])
//}





function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    console.log(permission)
    if (permission === 'granted') {
        console.log('Notification permission granted.');
        GetToken()
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

requestPermission()
//
//
//
function GetToken(){
  getToken(messaging, { vapidKey: vapidKey })
  .then((currentToken) => {
    if (currentToken) {
      console.log('FCM Registration Token:', currentToken);
      //sendingToServer(currentToken,idUser)
      // Envoyez le token à votre serveur ici
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}


//function sendingToServer(token,idSend){
//  //logique du code
//  axios({
//    method:'post',
//    withCredentials:true,
//    url:`${url}/fcm/received`,
//    data:{token:token,idSend:idSend}
//  })
//  .then(()=>{
//    console.log('')
//  })
//  .catch((err)=>{
//    console.error(err)
//  })
//
//}


//import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom/client';
//import { configureStore } from '@reduxjs/toolkit';
//import thunk from 'redux-thunk';
//import logger from 'redux-logger';
//import rootReducer from './reducers';
//import App from './App';
//import { getTrends, getUsers } from './actions/user.action';

// Configure Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, logger),
  devTools: process.env.NODE_ENV !== 'production',
});


store.dispatch(getUsers());
store.dispatch(getTrends());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);