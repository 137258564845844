import React from "react";
import { useDispatch } from "react-redux";
import { Delete_post } from "../actions/user.action";

export const DeleteCard = (props) => {
    const dispatch = useDispatch();

    const DeleteIt = () => {
        dispatch(Delete_post(props.id));
    };

    return (
        <div onClick={() => {
            if (window.confirm('Voulez-vous supprimer ce post ?')) {
                DeleteIt();
            }
        }}>
            <img src="./img/icons/trash.svg" alt="trash"/>
        </div>
    );
};
