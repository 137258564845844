import React, { useContext, useEffect, useState } from "react";
import { UidContext } from "./AppContext";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { like_post, Unlike_post } from "../actions/user.action";

const Likebtn = ({ post }) => {
  const uid = useContext(UidContext);
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLike = async () => {
    if (!liked && !loading) {
      setLoading(true);
      await dispatch(like_post(post.post_id, uid));
      setLiked(true);
      setLoading(false);
    }
  };

  const handleUnLike = async () => {
    if (liked && !loading) {
      setLoading(true);
      await dispatch(Unlike_post(post.post_id, uid));
      setLiked(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    const verifi = post.likes.some(
      (like) => parseInt(like.likerUser_id) === uid
    );
    if (verifi) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  }, [uid, post.likes, post.likes_count, dispatch]);

  return (
    <div className="like-container">
      {uid === null && (
        <>
          <Popup
            trigger={<img src="./img/icons/heart.svg" alt="like" />}
            position={["bottom center", "bottom right", "bottom left"]}
            closeOnDocumentClick
          >
            <div>Connectez-vous pour aimer un post !</div>
          </Popup>
        </>
      )}

      {uid && !liked && (
        <img
          src="./img/icons/heart.svg"
          alt="like"
          onClick={handleLike}
          disabled={loading}
        />
      )}
      {uid && liked && (
        <img
          src="./img/icons/heart-filled.svg"
          alt="like"
          onClick={handleUnLike}
          disabled={loading}
        />
      )}
      <span>{post.likes_count}</span>
    </div>
  );
};

export default Likebtn;

//)
