import React, { useState } from 'react'
import axios from 'axios'
import SignInForm from './SignInForm'

const SignUpForm = () => {
  const [username,setUsername] = useState('')
  const [email,setEmail] = useState('')
  const[password,setPassword] = useState('')
  const[controlPassword , setControlPassword] = useState('')
  const url = process.env.REACT_APP_API_URL //|| 'http://localhost:3000/api' || 'https://sen-teranga.com/api'
  const [EmailError,setEmailError] = useState(false)
  const [formSub , setFormSub]  = useState(false)
  const handleRegester = async (e)=>{
    e.preventDefault()
    const ConfirmError = document.querySelector(".ConfirmError")
    const CheckError = document.querySelector('.CheckError')
    const check  = document.querySelector("#terms")

    if(password !== controlPassword || !check.checked){
      if(password !== controlPassword ){
     // console.log('confirmError ' + ConfirmError.innerHTML)
        ConfirmError.innerHTML = "Les mot de passe ne sont pas conformes"
        ConfirmError.style = "color:red"
      }
      if(!check.checked){
        console.log('CheckError ' + CheckError)
       CheckError.innerHTML = "Veuillez accepter nos conditions d'utilisation"
       CheckError.style = "color:red"
      }
    }else{
      await axios({
        method:'post',
        url:`${url}/connexion`,
        data:{
          username,password,email
        }
      })
      .then((res)=>{
        setFormSub(true)
      })
      .catch((err)=>{
        if(err.message.includes('message')){
          //console.log("error" + err.message)
          setEmailError(true)
          // gestion d'erreur a faire
        }
      })      
    }

  }

  return (
    <>
    {formSub ? (
      <>
      <span></span>
      <SignInForm/>
      <br/>
      <p className='success'>Identification réussie maintenant connectez-vous </p> 
      <br/>
      </>
    ):
    <form action='' onSubmit={handleRegester} id='sign-up-form'>
    <label htmlFor="username">Nom d'utilisateur</label> <br/>
    <input
      name='username'
      id='username'
      onChange={(e)=>{setUsername(e.target.value)}}
      required
      value={username}
      type='text'
     /> <br/>
     <label htmlFor='email'>Votre adresse mail</label> <br/>
     <input 
      name='email'
      id='email'
      onChange={(e)=>{setEmail(e.target.value)}}
      required
      value={email}
      type='text'
     />
     {EmailError && <div className='errors'>  <br/></div>}<br/>
     <label htmlFor='password'>Entrez votre mot de passe</label><br/>
     <input
      name='password'
      id='password'
      onChange={(e)=>setPassword(e.target.value)}
      value={password}
      type='password'
      required
     />
     <div className='passwordError'></div>
     <label htmlFor='ConfirmPassword'>Confirmez le mot de passe</label> <br/>
     <input
      name='ConfirmPassword'
      type='password'
      id='ConfirmPassword'
      value={controlPassword}
      required
      onChange={(e)=>setControlPassword(e.target.value)}
     />
      <div className='ConfirmError'></div>
      <input type='checkbox' id='terms'/> <br/>
      <label htmlFor='terms'>J'accepte les <a href='/' target='_blank' rel='noopener noreferre'>les contitions d'utilisation</a></label>
      <div className='CheckError'></div>
      <input type='submit' id='submit' value="Validez votre inscription"/>
  </form>
    }
 
    </>
  )
}

export default SignUpForm