import axios from "axios";
import React, { useContext, useState } from 'react'
import cookie from "cookie-js";
const url = process.env.REACT_APP_API_URL //|| 'http://localhost:3000/api' || 'https://sen-teranga.com/api'
const   LogOut = () => {
const removeCookie = (key)=>{
    if(typeof window !== "undefined"){
        cookie.removeCookie(key,{expires:1})
    }
}    
const logout = async()=>{
    await axios({
        method: 'post',
        withCredentials:true,
        url:`${url}/logout`
    })
    .then(()=>{
        removeCookie('jwt')
    })
    .catch((err)=>{
        console.log(err.message)
    })
    window.location.href = "/"
}
  return(
    <li onClick={logout} className="active-left-nav" ><img src="./img/icons/logout.svg" alt="logout"/></li>)
};

export default LogOut
