import React, { useState, useEffect } from "react";
import { Isempty } from "./day";
import { useDispatch, useSelector } from "react-redux";
import { HandleFollow } from "./profil/handle.follow";

const FriendSuggest = () => {
    const data = useSelector((state) => state.userReducer)
    const Allusers = data.Allusers
    const [friendlist, setFriendlist] = useState([])
    const [Isloading, setIsloading] = useState(true)
    const [IsOnce, setIsOnce] = useState(true)

    useEffect(() => {
        const friendsTrim = () => {
            const ArrayFriends = [];
            Allusers.forEach((user) => {
                if (user.followers) {
                    const Followers = JSON.parse(user.followers);
                    if (Array.isArray(Followers) && !Followers.includes(data.id)) {
                        ArrayFriends.push(user.id);
                    } else {
                        console.log({user: JSON.parse(user.followers)});
                        console.log({Followers: Followers});
                        console.log(Array.isArray(Followers));
                    }
                }
            });
            ArrayFriends.sort(() => 0.5 - Math.random());
            if (window.innerHeight > 780) {
                ArrayFriends.length = 5;
              } else if (window.innerHeight > 720) {
                ArrayFriends.length = 4;
              } else if (window.innerHeight > 615) {
                ArrayFriends.length = 3;
              } else if (window.innerHeight > 540) {
                ArrayFriends.length = 1;
              } else {
                ArrayFriends.length = 0;
              }
            setFriendlist(ArrayFriends);
            console.log({friendlist: ArrayFriends});
        };

        if (IsOnce && !Isempty(data) && !Isempty(data.id)) {
            friendsTrim();
            setIsOnce(false);
            setIsloading(false);
        }
    }, [data, data.id, IsOnce]);

    return (
        <div className="get-friends-container">
        <h4>Suggestions</h4>
        {Isloading ? (
          <div className="icon">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <ul>
            {friendlist &&
              friendlist.map((user) => {
                for (let i = 0; i < Allusers.length; i++) {
                  if (user === Allusers[i].id) {
                    return (
                      <li className="user-hint" key={user}>
                        <img src={`uploads/profil/${Allusers[i].picture}`} alt="user-pic" />
                        <p>{Allusers[i].username}</p>
                        <HandleFollow
                          idToFollow={Allusers[i].id}
                          type={"suggestion"}
                        />
                      </li>
                    );
                  }
                }
              })}
          </ul>
        )}
      </div>
    );
};

export default FriendSuggest;
