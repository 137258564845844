import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_POSTS, getPost } from "./user.action";
import { addComment } from "./user.action";
import { HandleFollow } from "../components/profil/handle.follow";
import EditDeleteComment from "./EditDeleteComment";

export const Cardcomment = ({ post }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer);
  const [text, setText] = useState("");

  const handleComment = (e) => {
    e.preventDefault();
    if (text) {
      dispatch(addComment(post.post_id, userData.id, text, userData.username))
        .then(() => dispatch(getPost()))
        .then(() => setText(""));
    }
  };
  const getUserImage = (commenterUserId) => {
    const user = userData.Allusers.find(
      (user) => `${user.id}` === commenterUserId
    );
    return user ? `/uploads/profil/${user.picture}` : "./img/icons/user.svg"; // Remplacez par une image par défaut si nécessaire
  };

  return (
    <div className="comments-container">
      {post.comments && post.comments.length !== 0 && post.comments.map((comment) => {
          return (
            <div
              className={
                comment.commenterUser_id === userData.id
                  ? "comment-container client"
                  : "comment-container"
              }
              key={comment.commenterUser_id}
            >
              <div className="left-part">
                <img src={getUserImage(comment.commenterUser_id)} alt="User" />
              </div>
              <>
                <div className="right-part">
                  <div className="comment-header">
                    <div className="pseudo">
                      <h3>{comment.commenterPseudo_id}</h3>
                      {parseInt(comment.commenterUser_id) !== userData.id && (
                        <HandleFollow
                          idToFollow={parseInt(comment.commenterUser_id)}
                          type={"card"}
                        />
                      )}
                    </div>
                    {/*<span>{timestampParser(comment.timestamp)}</span>*/}
                  </div>
                  <p>{comment.text ? comment.text : comment.comments_text}</p>
                  <EditDeleteComment comment={comment} postId={post.post_id} />
                </div>
              </>
              {/* Ajoutez ici le texte du commentaire ou d'autres éléments si nécessaire */}
            </div>
          );

      })}
      {userData.id && (
        <form action="" onSubmit={handleComment} className="comment-form">
          <input
            type="text"
            name="text"
            onChange={(e) => setText(e.target.value)}
            value={text}
            placeholder="Laisser un commentaire"
          />
          <br />
          <input type="submit" value="Envoyer" />
        </form>
      )}
    </div>
  );
};
