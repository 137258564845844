import React, { useState } from 'react'
import SignInForm from './SignInForm'
import SignUpForm from './signUpForm'
const   Log = (props) => {
    const [signUpModal,setSignUpModal] = useState(props.SignIn)
    const [SignInModal, setSignInModal] = useState(props.signUp)
    const handleModal = (e)=>{
        if(e.target.id === 'register'){
            setSignInModal(false)
            setSignUpModal(true)
        }else{
            setSignInModal(true)
            setSignUpModal(false)
        }
    }
  return<div>
    <div className='connection-form'>
      <div className='form-container'>
        <ul>
            <li onClick={handleModal} id='register' className={signUpModal ?  'active-btn' : null }>S'inscrire</li>
            <li onClick={handleModal} id='login' className={SignInModal ? 'active-btn' : null}>Se connecter</li>
        </ul>
        {signUpModal && <SignUpForm />}
        {SignInModal && <SignInForm />}
      </div>
    </div>
  </div>;
};

export default Log
