import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import LogOut from '../components/log/LogOut';
import { UidContext } from '../components/AppContext';
import userReducer from '../reducers/users.reducer';
import { useSelector } from 'react-redux';
import { SearchBar } from '../components/Search';
const NavBar = () => {
  const Uid = useContext(UidContext)
  const data = useSelector((state)=>state.userReducer)
  return(
  <nav>
  <div>
    <div className='nav-container'>
      <div className='logo'>
        <NavLink exact='true' to="/">
        <div className='logo'>
          <img src='./img/logo.png' alt='logo'/>
        </div>
        </NavLink>
      </div>
        <SearchBar />
      {Uid ? (
      <ul>
        <li></li>
        <li className='welcome'> 
        
          <NavLink exact='true' to="/" >
            <h1></h1>
          </NavLink>
        </li>
        <NavLink >
        <h4 id='h4' > Salut  {data.username} ! </h4>
           <LogOut />
        </NavLink>
    </ul>):(
      <ul>
          <NavLink exact='true' to="/profil">
            <li>
            <h4 id='h4'> Salut  {data.username} ! </h4>
            
            <button  style={{color:"aliceblue"}}>Se connecter</button></li>
          </NavLink>
      </ul>
    )}
    </div>
  </div>
  </nav>)
};

export default NavBar
