import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Card from "./card"
import { getPost } from "../actions/user.action"
import { useSelector } from "react-redux"
import { Isempty } from "./day"
const Threads = () => {
    const Data = useSelector((state) => state.userReducer)
    const dispatch = useDispatch()
    const posts = Data.Allposts
    const [loadPost, setLoadPost] = useState(true)
    useEffect(() => {
        if (loadPost) {
            dispatch(getPost())
            setLoadPost(false)
    }
    }, [loadPost, dispatch])


    return (<div className="thread-container">
        <ul>
            {!Isempty(posts) && (
                posts.map((post) => {
                    return <Card post={post} key={post.post_id} />
                })
            )}
        </ul>
    </div>)



}

export default Threads