import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Isempty } from "./day";
import { getTrends } from "../actions/user.action";
import { NavLink } from "react-router-dom";

export const Trends = () => {
  const userData = useSelector((state) => state.userReducer.Allusers);
  const posts = useSelector((state) => state.userReducer.Allposts);
  const trendLIst = useSelector((state) => state.trendsReducer.trends);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Isempty(posts)) {
      const PostArray = Object.keys(posts).map((i) => posts[i]);
      PostArray.sort((a, b) => b.likes_count - a.likes_count);
      PostArray.length = 3;
      dispatch(getTrends(PostArray));
    }
  }, [posts, dispatch]);

  return (
    <div className="trending-container">
      <NavLink exact to="/trendings">
        <ul>
          <h4>Tendances</h4>
          {trendLIst && Array.isArray(trendLIst) && trendLIst.length > 0 ? (
            trendLIst.map((post) => (
              <li key={post.id}>
                <div>
                  <>
                    {
                    post.picture &&
                    post.picture !== null &&
                    post.picture !== "" ? (
                      <img src={`uploads/profil/${post.picture}`} alt="post-pic" />
                    ) : (
                      <img
                        src={userData ? userData
                          .filter(
                            (user) => user.id === parseInt(post.posterUser_id)
                          )
                          .map((user) => `uploads/profil/${user.picture}`)
                          .join(""):''}
                        alt="user-pic"
                      />
                    )}
                  </>
                </div>
                <div className="trend-content">
                  <p>
                    {  
                      post.message !== null 
                      && post.message !== '' ? post.message : (
                        
                        "Ce post n'est pas disponible..."
                      )
                    }
                  </p>
                  <span>lire</span>
                </div>
              </li>
            ))
          ) : (
            <p>Chargement des tendances...</p>
          )}
        </ul>
      </NavLink>
    </div>
  );
};
