import React, { useEffect, useState } from 'react';
import NavBar from './pages/navbar';
import Routes from './components/routes';
import { UidContext } from './components/AppContext';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getUser } from './actions/user.action';
import { TokenVerify } from '.';
const url = process.env.REACT_APP_API_URL //|| 'http://localhost:3000/api' || 'https://sen-teranga.com/api'

function App() {
  const [uid, setUid] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchToken = async () => {
      await axios({
        method: 'get',
        url: `${url}/jwtid`,
        withCredentials: true,
      })
        .then((res) => {
          setUid(res.data.id);          
        })
        .catch((err) => {
          console.log(err);
          console.log('No token');
        });
    };
    fetchToken();
  }, []);

  useEffect(() => {
    
    if (uid) {
      dispatch(getUser(uid));
    }
  }, [uid, dispatch]);

  return (
    <UidContext.Provider value={uid}>
      <Routes />
    </UidContext.Provider>
  );
}

export default App;
