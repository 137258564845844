import { combineReducers } from 'redux';
import userReducer from './users.reducer';
import trendsReducer from './TrendReducer';
const rootReducer = combineReducers({
  userReducer,
  trendsReducer
   // Utilisation d'un alias clair pour le réducteur
});

export default rootReducer;
