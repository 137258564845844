import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import LogOut from "./log/LogOut";
import { UidContext } from "./AppContext";
const LeftNav = ()=>{
    const Uid = useContext(UidContext)
    return(
        <div className="left-nav-container">
            <div className="icons">
                <div className="icons-bis">
                    <br/>
                    <br/>
                    <NavLink to='/home' exact  className='active-left-nav' activeClassName="active-left-nav">
                         <img src="./img/icons/home.svg" alt="home-img"/>
                          <p id="p">Accueil</p>
                    </NavLink> 
                    <NavLink to="/trendings" className='active-left-nav' exact activeClassName="active-left-nav">
                        <img src="./img/icons/rocket.svg"/>
                        <p id="p">Tendances</p>
                    </NavLink> 
                    <NavLink to='/shop' className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/shopify.svg"  id="svg" />
                        <p id="p">Shop</p>
                    </NavLink>
                    <NavLink to='/formations' className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/bank.svg"  id="svg" />
                        <p id="p">Formations</p>
                    </NavLink>

                    <NavLink to='/profil' className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/user.svg"/>
                        <p id="p">Profil</p>
                    </NavLink>
                    <NavLink to='https://www.facebook.com/profile.php?id=61564984761270' target="_blank" className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/facebook.svg" id="svg" />
                        <p id="p">Facebook</p>
                    </NavLink>
                    <NavLink to='https://wa.me/221704291214' target="_blank" className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/whatsapp.svg"  id="svg" />
                        <p id="p">Whatsapp</p>
                    </NavLink>
                    <NavLink to='/infos' className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/info.svg"  id="svg" />
                        <p id="p">Infos</p>
                    </NavLink>
                    <NavLink to='/users' className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/users-alt.svg" id="svg" />
                        <p id="p">Communauté</p>
                    </NavLink>
                    <NavLink to='/signalform' className='active-left-nav' exact activeClassName='active-left-nav'>
                        <img src="./img/icons/napster.svg"  id="svg" />
                        <p id="p">Signaler/Avis</p>
                    </NavLink>
                    {Uid ? (
                        <ul>
                            <li></li>
                            <li className='welcome'>
                            <NavLink exact='true' to="/profil">
                                <h1></h1>
                            </NavLink>
                            </li>
                            <NavLink >
                                <LogOut />
                            </NavLink>
                        </ul>):(
                        <ul>
                            <li></li>
                            <NavLink exact='true' to="/profil" className='active-left-nav'>
                            <img src="./img/icons/login.svg" alt="logIn"/>
                               <p id="p">Se connecter</p>
                            </NavLink>
                        </ul>
                        )}

                </div>
            </div>
        </div>
    )
}

export  default LeftNav