import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const SearchBar = () => {
  const posts = useSelector((state) => state.userReducer.Allposts);
  const dispatch = useDispatch();
  const [start, setStart] = useState(false);
  const [allowheader, setAllowheader] = useState(false);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([]);

  const handleSearchValue = (e) => {
    setSearch(e.target.value);
    setStart(true);
  };
  const handleDel = () =>{
    setTimeout(() => {
      setAllowheader(false)
    }, 1000); 
  }
  const handleScrollToPost = (postId) => {
    const element = document.getElementById(`id${postId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setAllowheader(false)
    }
  };

  useEffect(() => {
    if (start && search !== "") {
      if (posts) {
        const result = posts.filter((post) =>
          JSON.stringify(post.message)
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim())
        );
        if (result.length > 0) {
          setAllowheader(true);
          setValue(result);
        } else {
          setAllowheader(false);
        }
      }
    }
  }, [search, posts, start]);

  return (
    <div className="search">
      <input
        type="search"
        style={{borderRadius:'3px'}}
        id="search"
        placeholder="Rechercher ici une publication"
        onBlur={handleDel}
        onChange={handleSearchValue}
      />
      <br />
      {allowheader && value.length > 0 && (
        <ul id="ul"

        >
          Clique pour basculer vers la publication
          {value.map((post) => {
            return (
              <article id="article" key={post.post_id}>
                <button onClick={() => handleScrollToPost(post.post_id)}>
                  {post.message}
                </button>
              </article>
            );
          })}
        </ul>
      )}
    </div>
  );
};
