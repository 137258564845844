import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadPicture } from "../../actions/user.action";


const Uploadimg = () => {
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.userReducer);
    const [display,setDisplay] = useState(false)
    const handlePicture = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file); // ajoutez le fichier au formData
        formData.append("name", data.username);
        formData.append("id", data.id);
        if(file && file.size >= 400000
            && 
            (file && file.type === "image/png") ||
            file.type === "image/jpg" ||
            (file.type === "image/jpeg")
        ){
            document.querySelector('.error').innerText = "Vous devez prendre une image moins lourde "
        }else{
            document.querySelector('.error').innerText = ""
            dispatch(uploadPicture(formData, data.id));
            setDisplay(false)
        }
        document.querySelector('.success').innerHTML=''

    };
    useEffect(()=>{
        if(file !== null){
            document.querySelector('.success').innerHTML = 'Image selectionnée cliquez sur appliquer'
        }
        if(file){
           setDisplay(true) 
        }
 
    },[file])
    return (
        <div>
            <form onSubmit={handlePicture} className="upload-pic">
                <label htmlFor="file">Uploader une image</label> <br />
                <div className="success"></div><br/>
                <input
                    type="file"
                    required
                    id="file"
                    name="file"
                    onChange={(e) => setFile(e.target.files[0])}
                /> <br />
               {display && ( <input type="submit" value="Appliquer" id="submit"  />)}
                <br/>
                <div className="error"></div>
            </form>

        </div>
    );
};

export default Uploadimg;
