import React, { useContext } from 'react'
import LeftNav from '../components/leftnav'
import { UidContext } from '../components/AppContext'
import { useSelector } from 'react-redux'
import Card from '../components/card'
import { Isempty } from '../components/day'
import { isArray } from 'lodash'
import { Trends } from '../components/Trends'
import { NavLink } from 'react-router-dom'
import NavBar from './navbar'
import FriendSuggest from '../components/FriendsList'
const Trendings = () => {
  const uid = useContext(UidContext)
  const posts = useSelector((state) => state.trendsReducer.trends)

  return (
    <>
      <div className="projet">
        <div className="header-content">
        <NavBar />
        </div>
        <div className="glob">
          <LeftNav />
          <div className="home">
            <div className="main">
              <div className="home-header">
                <div className="article-section">
                  <img src="./img/icons/warning.png" style={{ width: "5%" }} />
                  <article>
                    <h5>
                      <NavLink to="/infos" target="_blank">
                        <a target="_blank">
                          {" "}
                          <mark>
                            {" "}
                            Nous encourageons les utilisateurs...{" "}
                          </mark>{" "}
                          voir plus{" "}
                        </a>
                      </NavLink>
                    </h5>
                  </article>
                </div>
                <ul>
                {!Isempty(posts[0]) && (
                  posts.map((post) => <Card post={post} key={post.posterUser_id} />)
                )}
              </ul>              
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="right-side-container">
              <div className="wrapper">
                <FriendSuggest />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default Trendings

