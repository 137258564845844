import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteComment } from "./user.action";
import { editComment } from "./user.action";
import { UidContext } from "../components/AppContext";

const EditDeleteComment = ({ comment, postId }) => {
  const [isAuthor, setIsAuthor] = useState(false);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(comment.comments_text);
  const uid = useContext(UidContext);
  const dispatch = useDispatch();

  const handleEdit = async (e) => {
    e.preventDefault();
    if (text) {
      console.log({message: text})
      await dispatch(editComment(postId, comment.comment_id, text));
      var input  = document.querySelector('#input').value
      console.log(input)
      setEdit(false);
    }
  };


  const handleDelete = async () => {
    if (window.confirm("Voulez-vous supprimer ce commentaire ?")) {
      await dispatch(deleteComment(postId, comment.comment_id));
    }
  };
  
  //const handleDelete = () => dispatch(deleteComment(postId, comment.comment_id))
  const handleChange = (e)=>{
    setText(e.target.value)
  }
  useEffect(() => {
    const checkAuthor = () => {
      if (uid === parseInt(comment.commenterUser_id)) {
        setIsAuthor(true);
      }
    };
    checkAuthor();
  }, [uid, comment.commenterUser_id , comment.comments_text]);


  return (
    <div className="edit-comment">
      {isAuthor && edit === false && (
        <span onClick={() => setEdit(!edit)}>
          <img src="./img/icons/edit.svg" alt="edit-comment" />
        </span>
      )}
      {isAuthor && edit && (
        <form action="" onSubmit={handleEdit} className="edit-comment-form">
          <label htmlFor="text" onClick={() => setEdit(!edit)}>
            Editer
          </label>
          <br />
          <input
            type="text"
            name="text"
            onChange={handleChange}
            defaultValue={comment.comments_text}
            id="input"
          />
          <br />
          <div className="btn">
            <span
              onClick={() => {
                  handleDelete();
              }}
            >
              <img src="./img/icons/trash.svg" alt="delete" />
            </span>
            <input type="submit" value="Valider modification" />
          </div>
        </form>
      )}
    </div>
  );
};

export default EditDeleteComment;