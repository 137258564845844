import axios from 'axios';

export const GET_USER = 'GET_USER';
const url = process.env.REACT_APP_API_URL //|| 'http://localhost:3000/api' || 'https://sen-teranga.com/api'

// Action creator
export const getUser = (uid) => {
  return (dispatch, getState) => {
    console.log('prev state', getState());
    return axios
      .get(`${url}/${uid}`, { withCredentials: true })
      .then((res) => {
        console.log('action', { type: GET_USER, payload: res.data });
        dispatch({ type: GET_USER, payload: res.data });
      })
      .catch((err) => console.error({message:'Une erreur est survenue'}));
  };
}
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE'

export const uploadPicture = (data,id)=>{
  return (dispatch) =>{
    return axios
      .post(`${url}/${id}/upload`,data,{withCredentials:true})
      .then((res)=>{
        return axios 
        .get(`${url}/${id}`,{withCredentials:true})
        .then((res)=>{
          dispatch({type:UPLOAD_PICTURE , payload: res.data.picture})
         // console.log({res: res})
        })
      })
      .catch((err)=>{
        console.error({message:'Une erreur est survenue'})
      })
  }
}


export const UPDATE_BIO = 'UPDATE_BIO'

export const updateBio = (userId,bio) =>{
  return(dispatch)=>{
    return axios({
      method:'put',
      url:`${url}/${userId}`,
      data:bio,
      withCredentials:true
    })
    .then((res)=>{
      dispatch({type:UPDATE_BIO, payload: bio.content})

    })
    .catch((err)=>{
      console.error({message:'Une erreur est survenue'})
    })
}}

export const GET_USERS = 'GET_USERS'

export const getUsers = ()=>{
  return (dispatch)=>{
    return axios
      .get(`${url}`,{withCredentials:true})
      .then((res)=>{
        dispatch({type:GET_USERS , payload:res.data})
        console.log({result:res.data})
      })
      .catch((err)=>{
        console.log(err)
        console.error({message:'Une erreur est survenue'})
      })
    }
}

export const FOLLOW = 'FOLLOW'

export const Follow = (followerId , idToFollow) =>{
  return (dispatch ) =>{
    return axios({
      method:'patch',
      url:`${url}/${followerId}/follow`,
      withCredentials:true,
      data: {idToFollow}
    })
    .then((res)=>{
      dispatch({type:FOLLOW , payload: res.data})
      console.log({idTof:{idToFollow}})
    })
    .catch((err)=>{
      console.error({message:'Une erreur est survenue'})
    })
  }
}

export const UNFOLLOW = 'UNFOLLOW'

export const Unfollow = (userId,idToUnFollow) =>{
  return(dispatch) =>{
    return axios
    .patch(`${url}/${userId}/unfollow`,{idToUnFollow},{withCredentials:true})
    .then((res)=>{
      dispatch({type:UNFOLLOW , payload:res.data})
    })
    .catch((err)=>{
      console.log(err.message)
    })
  }
}


export const GET_POSTS = 'GET_POSTS'
export const GET_ALL_POSTS = 'GET_ALL_POSTS'

export const getPost = (num) => {
  return (dispatch) => {
    return axios
      .get(`${url}/test`, { withCredentials: true })
      .then((res) => {
        const array = res.data.slice(0, num).reverse();
        dispatch({ type: GET_POSTS, payload: array });
        dispatch({ type: GET_ALL_POSTS, payload: res.data });
      })
      .catch((err)=>{
        console.log(err.message)
      })
  };


};






export const LIKE_POST = `LIKE_POST`

export const like_post = (postId,userId)=>{
  return (dispatch) => {
    return axios({
      method:'patch',
      url:`${url}/posts/${postId}/like/${userId}`,
      withCredentials:true,
      data:{id:userId}
    })
    .then((res)=>{
      dispatch({type:LIKE_POST , payload:{postId,userId}})
    })
    .catch((err)=>{
      console.log(err.message)
    })
  }
}

export const UNLIKE_POST = 'UNLIKE_POST'

export const Unlike_post = (postId,userId)=>{
  return (dispatch) =>{
    return axios({
      method:'patch',
      url:`${url}/posts/${postId}/unlike/${userId}`,
      data:{id:userId}
    })
    .then((res)=>{
      dispatch({type:UNLIKE_POST , payload:{postId,userId}})
    })
    .catch((err)=>{
      console.log(err.message)
    })
  }
} 

export const UPDATE_POST = 'UPDATE_POST'

export const UpdatePost = (message , postId , userId) =>{
  return(dispatch)=>{
    return axios ({
      method:'put',
      url:`${url}/posts/update/${postId}`,
      data:{message},
      withCredentials:true
    })
    .then((res)=>{
      dispatch({type:UPDATE_POST , payload: {message , postId}})
    })
    .catch((err)=>{
      console.log(err.message)
    })
  }  
}

export const DELETE = 'DELETE_POST'

export const Delete_post = (postId)=>{
  return(dispatch)=>{
    return axios({
      method:'delete',
      url:`${url}/posts/delete/${postId}`,
    })
    .then((res)=>{
      console.log('first')
      dispatch({type:DELETE , payload: {postId} })
    })
    .catch((err)=>{
      console.log(err.message)
    })
  }
}

// comments
export const ADD_COMMENT = "ADD_COMMENT";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

// trends
export const GET_TRENDS = "GET_TRENDS";
// Add post

export const ADD_POST = 'ADD_POST'

export const addPost = (data) => {
  return (dispatch) => {
    return axios.post(`${url}/posts/addPost`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch({ type: ADD_POST, payload: res.data });
    })
    .catch((err)=>{
      console.log(err)
    })
  };
};

export const addComment = (postId, commenterId, text, commenterPseudo) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${url}/posts/${postId}/comment/${commenterId}`,
      data: { commenterId, text, commenterPseudo },
    })
      .then((res) => {
        dispatch({ type: ADD_COMMENT, payload: { postId } });
      })
      .catch((err)=>{
        console.log(err.message)
      })
  };
};

export const editComment = (postId, commentId, text) => {
  return (dispatch) => {
    return axios({
      method: "patch",
      url: `${url}/posts/${postId}/editcomment/${commentId}`,
      data: { commentId, text },
    })
      .then((res) => {
        dispatch({ type: EDIT_COMMENT, payload: { postId, commentId, text } });
      })
      .catch((err)=>{
        console.log(err.message)
      })
  };
};
export const deleteComment = (postId, commentId) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${url}/posts/${postId}/delete_comment/${commentId}`,
      data: { commentId },
    })
      .then((res) => {
        dispatch({ type: DELETE_COMMENT, payload: { postId, commentId } });
      })
      .catch((err)=>{
        console.log(err.message)
      })
  };
};

export const getTrends = (sortedArray) => {
  return (dispatch) => {
    dispatch({ type: GET_TRENDS, payload: sortedArray });
  };
};