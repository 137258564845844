import React from "react";
import LeftNav from "../components/leftnav";
import NavBar from "./navbar";
const Users = () => {
  return (
    <>
      <div className="projet">
        <div className="header-content">
          <NavBar />
        </div>
        <div className="glob">
          <LeftNav />
          <div className="home">
            <div className="main">
              <div className="home-header" style={{ marginTop: "7vh" }}>
                <h5>
                  Cette page est en construction..... <br />
                  restez connecter pour en savoir plus
                </h5>
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          </div>
          <div className="right-side"></div>
        </div>
      </div>
    </>
  );
};

export default Users;
