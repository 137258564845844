import React, { useState } from 'react';
import axios from 'axios';
import { TokenVerify } from '../..';
import { useSelector } from 'react-redux';

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [user_password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  
  const url = process.env.REACT_APP_API_URL
  const dataUser = useSelector((state) => state.userReducer);


  const handleLogin = (e) => {
    e.preventDefault();
    let uid ;
    if(dataUser.id !== 0 || null){
     uid = dataUser.id 
    }
    else{
     uid = 1
    }
    // Reset errors
    setEmailError('');
    setPasswordError('');
    setGeneralError('');

    axios({
      method: 'post',
      url: `${url}/signIn`,
      withCredentials: true,
      data: {
        email,
        user_password,
      },
    })
      .then((res) => {
        // Assuming login is successful, redirect the user
        window.location.href = '/';

        // After redirection, verify token (this may need to be managed better depending on lifecycle)
        TokenVerify();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            setEmailError("Erreur lors de la vérification de l'utilisateur");
          } else if (err.response.status === 404) {
            setEmailError("Ce mail n'existe pas dans nos bases de données, veuillez créer un compte");
          } else if (err.response.status === 400) {
            setPasswordError("Mot de passe incorrect");
          }
        } else {
          setGeneralError('Une erreur est survenue lors de la connexion. Veuillez réessayer.');
        }
      });
  };

  return (
    <form onSubmit={handleLogin} id="signInForm" method="post">
      <label htmlFor="email">Email</label> <br />
      <input
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Entrez votre adresse mail"
        name="email"
        id="email"
        value={email}
        required
      />
      <br />
      {emailError && <div className="error">{emailError}</div>}
      <br />
      <label htmlFor="password">Mot de passe</label> <br />
      <input
        type="password"
        placeholder="Entrez votre mot de passe"
        name="user_password"
        id="password"
        value={user_password}
        onChange={(e) => setPassword(e.target.value)}
        required
      /> <br />
      {passwordError && <div className="error">{passwordError}</div>}
      <br />
      {generalError && <div className="error">{generalError}</div>}
      <br />
      <input type="submit" value="Se connecter" />
    </form>
  );
};

export default SignInForm;
