import { ADD_COMMENT, getUser, UNFOLLOW, UPLOAD_PICTURE } from "../actions/user.action";

const initialState = {
  id: null,
  username: '',
  email: '',
  bio: null,
  followers: null,
  following: null,
  likes: null,
  picture: null,
  createdAt: '',
  name:"",Allposts:null
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_USER':
      return { ...state, ...action.payload };
    case 'UPLOAD_PICTURE':
      return{
        ...state , picture:action.payload
      }
    case 'UPDATE_BIO':
      return{
        ...state , bio:action.payload
      }
    case 'GET_USERS':
      return {
        ... state , Allusers:action.payload
      }
    case 'FOLLOW':
      return{
        ...state , follow:action.payload
      }
    case 'UNFOLLOW':
      return{
      ...state , unfollow:action.payload}
    case 'GET_POSTS':
      return{
        ...state , Allposts:action.payload
      }
    case 'DELETE_POST':
        return {
          ...state,
          Allposts: state.Allposts.filter((post)=> post.post_id !== action.payload.postId)}
          case 'LIKE_POST':
            return {
              ...state,
              Allposts: state.Allposts.map((post) => {
                if (post.post_id === action.payload.postId) {
                  // Vérifiez si l'utilisateur a déjà liké
                  const hasLiked = post.likes.some((like) => like.likerUser_id === action.payload.userId);
                  if (!hasLiked) {
                    return {
                      ...post,
                      likes: [...post.likes, { likerUser_id: action.payload.userId }],
                      likes_count: post.likes_count + 1,
                    };
                  }
                }
                return post;
              })
            };
          
          case 'UNLIKE_POST':
            return {
              ...state,
              Allposts: state.Allposts.map((post) => {
                if (post.post_id === action.payload.postId) {
                  return {
                    ...post,
                    likes: post.likes.filter((like) => like.likerUser_id !== action.payload.userId),
                    likes_count: post.likes_count > 0 ? post.likes_count - 1 : 0,
                  };
                }
                return post;
              })
            };
          
        case 'UPDATE_POST':
          return{
            ...state,
            Allposts: state.Allposts.map((post)=>{
              if(post.post_id === action.payload.postId){
                return {
                  ...post , 
                  message: action.payload.message
                }
              }
              return post
            })
            
          }
          case 'DELETE_POST':
            return state.filter((post) => post.post_id !== action.payload.postId);
            case 'EDIT_COMMENT':
              return {
                  ...state,
                  Allposts: state.Allposts.map((post) => {
                      if (post.post_id === action.payload.postId) {
                          return {
                              ...post,
                              comments: post.comments.map((comment) => {
                                  if (comment.comment_id === action.payload.commentId) {
                                      return {
                                          ...comment,
                                          text: action.payload.text,
                                      };
                                  } else {
                                      return comment;
                                  }
                              }),
                          };
                      } else {
                          return post;
                      }
                  }),
              };
                   
            case 'DELETE_COMMENT':
              return {
                  ...state,
                  Allposts: state.Allposts.map((post) => {
                      if (parseInt(post.post_id) === action.payload.postId) {
                          return {
                              ...post,
                              comments: post.comments.filter(
                                  (comment) => comment.comment_id !== action.payload.commentId
                              ),
                              comments_count : post.comments_count -1
                          };
                      }
                      return post;
                  }),
              };
              case 'ADD_POST':
                return {
                  ...state,
                  payload: action.payload
                };
              
              case 'ADD_COMMENT':
                return{
                  ...state,
                  Allposts: state.Allposts.map((post)=>{
                    if(parseInt(post.post_id)===action.payload.postId){
                      return {
                        ...post,
                        comments:[
                          ...post.comments,
                          {comment_id:action.payload.postId,
                           commentUser_id:action.payload.commenterId,
                           comments_text:action.payload.text,
                           commenterPseudo_id:action.payload.commenterPseudo

                          }
                        ]
                      }
                    }
                    else return post
                  })
                }
          
          default:
            return state;

  }
}

