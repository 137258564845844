import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from "../../pages/home";
import Trendings from "../../pages/trendings";
import Profil from "../../pages/profil";
import NavBar from '../../pages/navbar';
import Info from '../../pages/info';
import SignalForm from '../../pages/SignalForm';
import Formations from '../../pages/Formations';
import Shop from '../../pages/shop';
import Users from '../../pages/users';
import { Mail } from '../../pages/mail';
const Index = () => {
  return (
    <Router>
        <NavBar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/trendings" element={<Trendings />} />
            <Route path="/signalform" element={<SignalForm />} />
            <Route path='/infos' element={<Info />}/>
            <Route path='/shop' element={<Shop />}/>
            <Route path='/users' element={<Users />}  />
            <Route path='/mail' element={<Mail />}/>
            <Route path='/formations' element={<Formations />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </Router>
  );
};

export default Index;
