import React, { useEffect, useState } from "react";
import { dateParser, Isempty } from "./day";
import { useDispatch, useSelector } from "react-redux";
import { HandleFollow } from "./profil/handle.follow";
import  Likebtn from "./likebtn";
import  {DeleteCard} from './DeleteCard'
import { UpdatePost } from "../actions/user.action";
import { Cardcomment } from "../actions/Cardcomment";

const Card = ({ post }) => {
  const data = useSelector((state) => state.userReducer);
  const [updatePost , setUpdatePost] = useState(false)
  const [updateText , setUpdateText] = useState(null)
  const [Showcomment , setShowcomment] = useState(false)
  const users = data.Allusers;
  const [Isloading, setLoading] = useState(true);
  const dispatch = useDispatch()

  useEffect(() => {
    if (!Isempty(users[0])) {
      setLoading(false);
    }
  }, [users]);

  const getUserImage = () => {
    const user = users.find((user) => `${user.id}` === post.posterUser_id);
    return user ? `/uploads/profil/${user.picture}` : "default-image.png"; // Remplacez par une image par défaut si nécessaire
  };
  const getPseudo = ()=>{
     const pseudo = users.find((pseudo)=>`${pseudo.id}` === post.posterUser_id);
     return pseudo ? pseudo.username  : ''
  }
  const Items =  ()=>{
    if(updateText){
        dispatch(UpdatePost( updateText ,post.post_id ))
    }
    setUpdatePost(false)
  }


  return (
    <li className="card-container" key={post.id}>
      {Isloading ? (
        <i className="fas fa-spinner fa-spin"></i>
      ) : (
        <>
          <div className="card-left">
            <img src={getUserImage()} alt="User" />
          </div>
          <div className="card-right">
            <div className="card-header">
              <div className="pseudo">
                <h3>{getPseudo()}</h3> {/* Ajouté pour montrer le titre ou pseudo */} 
                {post.posterUser_id !== users.id &&  <HandleFollow idTofollow={post.posterUser_id} type={'Card'} />} 
              </div>
              <p id="date">{dateParser(post.createdAt)}</p>
            </div>
             {updatePost === false && post.message &&
              <p id={`id${post.post_id}`}>
                { post.message} </p>}
             {updatePost && (
              <>
                <div className="update-post">
                  <textarea
                    defaultValue={post.message}
                    onChange={(e)=>setUpdateText(e.target.value)}
                  />
                </div>
                <div className="button-container">
                  <button className="btn" onClick={Items}>
                    Valider les modifications
                  </button>
                </div>
              </>
             )             
            }            
            {post.picture &&  <img src={`/uploads/profil/${post.picture}`} id={`id${post.post_id}`} className="card-pic" alt="pic"/>}
            {data.id === parseInt(post.posterUser_id) && (
              <>
                <div className="button-container">
                  <div onClick={()=>setUpdatePost(!updatePost)}>
                    <img src="./img/icons/edit.svg" alt="edit"/>
                  </div>
                  <DeleteCard id={post.post_id}/>
                </div>
              </>
            )}
            <div className="card-footer">
                <div className="comment-icon">
                    <img src="./img/icons/message1.svg" alt="icons"  onClick={()=>setShowcomment(!Showcomment)} />
                    <span>{post.comments_count && post.comments_count}</span>
                </div>
                <Likebtn post={post} />
                <img src="./img/icons/share.svg" alt="share.svg"/>
            </div>
            {Showcomment && <Cardcomment post={post}/>}
          </div>
        </>
      )}
    </li>
  );
};


export default Card