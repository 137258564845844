import React, { useContext, useState } from 'react'
import LeftNav from '../components/leftnav'
import { UidContext } from '../components/AppContext'
import { Trends } from '../components/Trends'
import axios from 'axios'

const SignalForm = () => {
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [tel, setTel] = useState(null)
    const [type, setType] = useState(null)

    const url = process.env.REACT_APP_API_URL_TO //|| 'http://localhost:3000/api' || 'https://sen-teranga.com/api'
    const FormSubmit = async (e) => {
        e.preventDefault()
        return axios({
            method: 'post',
            url: `${url}/SignalForm`,
            data: {
                type, username, tel, email, message
            },
            withCredentials: true
        })
            .then(() => {
                alert('Formulaire envoyé...')
                window.location.href = '/home'
            })
    }
    return (
        <>
            <LeftNav />
            <div className='projet'>
                <div className='home'>
                    <div className='main'>
                        <div className="home-header">
                            <h3>Signaler un probleme ou laisser un commentaire</h3>
                            <form onSubmit={FormSubmit} className='Signal-form'>
                                <label>Avis ? ou Signaler</label><br/>
                                <figure>
                                    <select id='type' name='type' value={type}
                                        onChange={(e)=>setType(e.target.value)}
                                    >
                                        <optgroup>
                                            <option value='Defaut'>
                                                Signaler un defaut
                                            </option>
                                            <option value='Avis'> 
                                                Donner un avis
                                            </option>
                                        </optgroup>
                                    </select>
                                </figure>
                                <label>Prenom && nom</label>
                                <br /> <input type='text'
                                    id='username'
                                    name='username'
                                    placeholder='Prenom && nom' required
                                    value={username} onChange={(e) => setUsername(e.target.value)}
                                /> <br />
                                <label>Numero de telephone</label> <br />
                                <input type='tel'
                                    id='tel' name='tel'
                                    placeholder='+221....'
                                    required minLength='9' maxLength='14'
                                    value={tel} onChange={(e) => setTel(e.target.value)}
                                /> <br />
                                <label>Adresse mail</label><br />
                                <input type='email'
                                    id='email' name='email'
                                    required placeholder='email'
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}

                                /> <br />
                                <label>Le message</label><br />
                                <textarea name='message' id='message'
                                    value={message}
                                    onChange={(e)=>setMessage(e.target.value)}
                                    required>Votre message ici</textarea> <br />
                                <input type='submit' id='submit' />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <div className="right-side-container">
                        <div className="wrapper">
                            <Trends />
                            {/*uid && <FriendsHint />*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignalForm

