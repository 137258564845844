import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Isempty } from "../day";
import { Follow, Unfollow } from "../../actions/user.action";

export const HandleFollow = ({ idTofollow, type }) => {
  const userData = useSelector((state) => state.userReducer);
  const [followed, setFollowed] = useState(false);
  const dispatch = useDispatch();

  const handleFollow = () => {
    dispatch(Follow(userData.id, idTofollow))
      .then(() => {
        setFollowed(true) 
        alert('Désabonnement réussi')
    }) 
      .catch(err => console.error(err));
  };

  const handleUnfollow = () => {
    dispatch(Unfollow(userData.id, idTofollow))
      .then(() => setFollowed(false))
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (!Isempty(userData.following)) {
      setFollowed(userData.following.includes(idTofollow));
    }
  }, [userData.following, idTofollow]);

  return (
    <>
      {followed && !Isempty(userData.id) && (
        <span onClick={handleUnfollow}>
          {type === "suggestion" && <button className="unfollow-btn">Suivi</button>}
          {type === "Card" && <img src="./img/icons/checked.svg" alt="checked" />}
        </span>
      )}
      {!followed && !Isempty(userData.id) && (
        <span onClick={handleFollow}>
          {type === "suggestion" && <button className="follow-btn">Suivre</button>}
          {type === "Card" && <img src="./img/icons/check.svg" alt="check" />}
        </span>
      )}
    </>
  );
};
