import React, { useContext } from "react";
import LeftNav from "../components/leftnav";
import { UidContext } from "../components/AppContext";
import { useSelector } from "react-redux";
import Card from "../components/card";
import { Isempty } from "../components/day";
import { isArray } from "lodash";
import { Trends } from "../components/Trends";
import { NavLink } from "react-router-dom";
import NavBar from "./navbar";
import FriendSuggest from "../components/FriendsList";
import Trendings from "./trendings";
const Info = () => {
  const uid = useContext(UidContext);
  const posts = useSelector((state) => state.trendsReducer);
  return (
    <>
      <div className="projet">
        <div className="header-content">
          <NavBar />
        </div>
        <div className="glob">
          <LeftNav />
          <div className="home">
            <div className="main">
              <section className="article-section">
                <article>
                  <h1>Conditions d'Utilisation</h1>
                  Pour les Publications sur sur notre réseau social
                  professionnel,{" "}
                  <mark>
                    nous encourageons les utilisateurs à publier des contenus
                    exclusivement orientés vers des sujets professionnels
                  </mark>
                  . Les publications doivent refléter des discussions sur le
                  travail, les affaires, l'éducation, ou des sujets connexes.
                  <mark>
                    Nous n'autorisons pas de publications à caractère
                    divertissant, personnel, ou relationnel
                  </mark>
                  . Les échanges doivent rester centrés sur le développement
                  professionnel.
                  <h1>Mises à Jour et Évolution du Site</h1>
                  Notre plateforme est en constante évolution pour mieux
                  répondre à vos besoins professionnels. Prochainement, nous
                  introduirons des <mark>pages de formation</mark> en ligne pour
                  améliorer vos compétences, des <mark>pages de vente</mark> en
                  ligne pour promouvoir vos produits et services, ainsi qu'une
                  page de <mark>communauté</mark> pour favoriser les échanges
                  entre professionnels. De plus,{" "}
                  <mark>une page de freelancing</mark> sera disponible pour ceux
                  qui cherchent des opportunités de travail indépendant. Nous
                  nous engageons à enrichir continuellement notre réseau avec
                  d'autres fonctionnalités utiles pour vos projets
                  professionnels. Ces conditions visent à maintenir un
                  environnement centré sur le développement et la réussite
                  professionnelle de chacun.
                </article>
              </section>
            </div>
          </div>
          <div className="right-side">
            <div className="right-side-container">
              <div className="wrapper">
                <FriendSuggest />
                <Trends />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
