import axios from "axios";
import React, { useState } from "react";


export const Mail = ()=>{
    const [email,setEmail] = useState('')
    const handleSubmit = ()=>{
        axios({
            method:'post',
            url:`${process.env.REACT_APP_API_URL}/mailling`,
            withCredentials:true,
            data:{
                mail:email
            }
        })
        .then(()=>{
            alert('Email sended')
        })
        .catch((err)=>{
            console.log('une erreur est survenue')
        })
    }

    return(
    
            <form onSubmit={handleSubmit} style={{margin:'20%'}}>
                <label htmlFor="email" /><br/>
                <input style={{padding:'15px' , fontSize:'25px'}} type="email" placeholder="email" onChange={(e)=>setEmail(e.target.value)} required/><br/>
                <input  type="submit" placeholder="envoyer le mail"/>
            </form>
    )
}

