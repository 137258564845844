import React, { useContext } from "react";
import LeftNav from "../components/leftnav";
import Threads from "../components/theards";
import NewPostForm from "../actions/PostForm";
import { UidContext } from "../components/AppContext";
import { Trends } from "../components/Trends";
import Log from "../components/log";
import FriendSuggest from "../components/FriendsList";
import { NavLink } from "react-router-dom";
import { SearchBar } from "../components/Search";
import NavBar from "./navbar";
const Home = () => {
  const uid = useContext(UidContext);
  return (
    <>
      <div className="projet">
        <div className="header-content">
            <NavBar />
        </div>
        <div className="glob">
          <LeftNav />
          <div className="home">
            <div className="main">
              <div className="home-header">
                <div className="article-section">
                  <img src="./img/icons/warning.png" style={{ width: "5%" }} />
                  <article>
                    <h5>
                      <NavLink to="/infos" target="_blank">
                        <a target="_blank">
                        
                          <mark>
                          
                            Attention: Nous encourageons les utilisateurs...{" "}
                          </mark>{" "}
                          voir plus{" "}
                        </a>
                      </NavLink>
                    </h5>
                  </article>
                </div>
                {uid ? <NewPostForm /> : <Log signin={true} signup={false} />}
              </div>
              <Threads />
            </div>
          </div>
          <div className="right-side">
            <div className="right-side-container">
              <div className="wrapper">
                <Trends />
                {uid && <FriendSuggest />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
