import React, { useState } from "react";
import LeftNav from "../leftnav";
import Uploadimg from "./uploadimg";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../pages/navbar";
import { updateBio } from "../../actions/user.action";
import { dateParser } from "../day";
import { HandleFollow } from "./handle.follow";

const UpdateProfil = ()=>{
    const data  = useSelector((state)=>state.userReducer)
    const dispatch = useDispatch()

    const [followingpopup , setFollowingpopup] = useState(false)
    const [followersPopup , setFollowersPopup] = useState(false)


    const followers = data.followers
    const [bio,setBio] = useState('')
    const[ updateForm , setUpdateForm ]= useState(false)
    const handleBio = ()=> {
        dispatch(updateBio(data.id,{content:bio}))
        setUpdateForm(false)
    }
    return (
        <div className="profil-container">
        <div className="header-content">
            <NavBar />
        </div>
            <LeftNav />
            <div className="update-container">
                <div className="left-part">
                    <p>Photo de profil</p>
                    <img src={`uploads/profil/${data.picture}`} alt="profile" />
                     <br/>
                    <Uploadimg />
                </div>
                <div className="right-part">
                    <div className="bio-update">
                        <h3>Bio</h3>
                        {updateForm === false && (
                            <>
                                <p onClick={()=>{setUpdateForm(!updateForm)}}>{data.bio}</p>
                                <button onClick={()=>{setUpdateForm(!updateForm)}}>Modifier bio</button>
                            </>
                        )}
                        {updateForm &&(
                            <>
                            <textarea typeof="text"defaultValue={data.bio} name="bio" onChange={(e)=>setBio(e.target.value)}></textarea>
                            <button onClick={handleBio}>valider les modifications</button>
                            </>
                        )}
                    </div>
                    <h4>Membre depuis: {dateParser(data.createdAt)}</h4>
                    <h5 onClick={()=>{setFollowersPopup(true)}}>Followers {followers ? JSON.parse(followers).length  : ''}</h5>
                    <h5 onClick={()=>{setFollowingpopup(true)}}>Suivis {data.following ? JSON.parse(data.following).length : ''}</h5>
                </div>
                {followingpopup && (
                    <>
                        <div className="popup-profil-container">
                            <div className="modal">
                                <h3>Suivis</h3>
                                <span className="cross" onClick={()=>{setFollowingpopup(false)}}>x</span>
                                <ul>
                                    <>
                                        {data.Allusers.map(user=>{
                                            for(var i = 0 ; i < data.following.length ; i++){
                                                if(`${user.id}` === data.following[i]){

                                                      return (
                                                          <li key={user.id}>
                                                              <img src={`uploads/profil/${user.picture}`} alt='image'/>
                                                              <h4>{user.username}</h4>                                                              
                                                             
                                                              <div className="follow-handler">
                                                                <HandleFollow idTofollow={user.id} type={'suggestion'}/>
                                                              </div>
                                                          </li>
                                                      )
                                                }
                                                //else{
                                                //    console.log('None')
                                                //}
                                            }
                                        })}
                                    </>
                                </ul>
                            </div>
                        </div>
                    </>
                 )}
                 {followersPopup &&(
                    <>
                        <div className="popup-profil-container">
                            <div className="modal">
                                <h3>Followers</h3>
                                <span className="cross" onClick={()=>{setFollowersPopup(false)}}>x</span>
                                <ul>
                                    <>
                                        {data.Allusers.map((user)=>{
                                            for(var i=0 ; i < followers.length+1 ; i++){
                                                if(`${user.id}`=== data.followers[i]){
                                                    return (
                                                        <li key={user.id}>
                                                            <img src={`uploads/profil/${user.picture}`} alt="image"/>
                                                            <h4>{user.username}</h4>
                                                            <div className="follow-handler">
                                                                <HandleFollow idTofollow={user.id} type={'suggestion'}/>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            }
                                        })}
                                    </>
                                </ul>
                            </div>
                        </div>
                    </>
                 )}
            </div>
        </div>
    )}
export default UpdateProfil