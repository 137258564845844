import React from 'react'
import { UidContext } from '../components/AppContext';
import { useContext } from 'react';
import Log from '../components/log'
import NavBar from './navbar';
import UpdateProfil from '../components/profil/update.profile';
const Profil = () => {
const uid =  useContext(UidContext)
  return(
  <div>
    <div className='profil-page'>
    {uid ? (<UpdateProfil/>):(
      <h3 id='myH11'>Enregestrez-vous sur SENBUSINESS</h3>,
      <div className='log-container'>
        <div className='img-container'>
          <Log signIn={false} signUp={true} />
        </div>
      </div>    
  )}
    </div>
  </div>);
};

export default Profil
